<template>
  <div class="f-p">
    <fm-title title-text="关联表" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu"></fm-title>
    <div class="f-p-data" v-for="(item, index) in dataList" :key="index">
      <div class="del-btn">
        <fm-btn @click="del(index)">删除</fm-btn>
      </div>
      <conf-join-table :value="item" @change="valueChange($event, index)"></conf-join-table>
    </div>
  </div>
</template>

<script>
import ConfJoinTable from './conf_join_table'
let dcopy = require('deep-copy')

export default {
  name: 'joins',
  components: {
    ConfJoinTable
  },
  props: {
    value: Array
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value: {
      handler () {
        this.dataList = dcopy(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      titleMenus: [{
        key: 'add',
        label: '新增'
      }],
      dataList: []
    }
  },
  methods: {
    del (i) {
      this.dataList = this.dataList.filter((v, index) => {
        return index !== i
      })
      this.$emit('change', dcopy(this.dataList))
    },
    clickTitleMenu () {
      this.dataList.push({})
    },
    valueChange (data, index) {
      this.dataList[index] = data
      this.$emit('change', dcopy(this.dataList))
    }
  }
}
</script>

<style lang="less" scoped>
.f-p {
  align-items: center;
  margin-left: 1rem;
  width: calc(100% - 1rem);
  .f-p-item {
    border: 1px solid #dcdee2;
    width: calc(100% - 1rem);
  }
}
.del-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.f-p-data {
  position: relative;
}
</style>
