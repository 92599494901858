<template>
  <div style="border: 1px solid #dcdee2;">
    <div class="form-item">
      <div class="form-item-label">关联类型</div>
      <div class="form-item-content">
        <fm-select 
          style="width: 10rem;margin-left: 1rem;"
          @change="valueChange"
          v-model="type">
          <fm-option
            v-for="itemO in [{key: 'left join', label: '左关联'}, {key: 'right join', label: '右关联'}]"
            :key="itemO.key"
            :label="itemO.label" :value="itemO.key">
          </fm-option>
        </fm-select>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">表名</div>
      <div class="form-item-content">
        <div style="width: 10rem;margin-left: 1rem;">
          <fm-input @change="valueChange" v-model="table"></fm-input>
        </div>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">别名</div>
      <div class="form-item-content">
        <div style="width: 10rem;margin-left: 1rem;">
          <fm-input @change="valueChange" v-model="alias"></fm-input>
        </div>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">返回结果</div>
      <div class="form-item-content">
        <result @change="valueChange" v-model="result"></result>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">关联条件</div>
      <div class="form-item-content">
        <conditions :is-join="true" @change="valueChange" v-model="onConditions"></conditions>
      </div>
    </div>
  </div>
</template>

<script>
import Result from './result'
import Conditions from './conditions'
let dcopy = require('deep-copy')

export default {
  name: 'conf-join-table',
  components: {
    Result,
    Conditions
  },
  props: {
    value: Object
  },
  data () {
    return {
      type: '',
      table: '',
      alias: '',
      onConditions: [],
      result: {
        isAll: true,
        fields: [],
        excludeColumns: []
      },
      joins: []
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value: {
      handler () {
        this.type = this.value.type || ''
        this.table = this.value.table || ''
        this.alias = this.value.alias || ''
        this.onConditions = this.value.onConditions ? dcopy(this.value.onConditions) : []
        this.result = dcopy(this.value.result) || {
          isAll: true,
          fields: [],
          excludeColumns: []
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleClose () {
      this.$emit('handleClose')
    },
    valueChange () {
      this.$emit('change', {
        type: this.type,
        table: this.table,
        alias: this.alias,
        result: dcopy(this.result),
        onConditions: dcopy(this.onConditions)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title-c {
  display: flex;
  align-items: center;
}
.form-item-table {
  height: 10rem;
  width: 100%;
}
.form-item {
  display: flex;
  margin: 0.5rem 1rem;
  display: flex;
  align-items: center;
  .form-item-label {
    min-width: 5rem;
    max-width: 5rem;
    width: 5rem;
  }
  .form-item-content {
    flex: 1;
  }
}
</style>
