<template>
  <div>
    <div class="form-item">
      <div class="form-item-label">表名</div>
      <div class="form-item-content">
        <div style="width: 10rem;margin-left: 1rem;">
          <fm-input @change="valueChange" v-model="table"></fm-input>
        </div>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">别名</div>
      <div class="form-item-content">
        <div style="width: 10rem;margin-left: 1rem;">
          <fm-input @change="valueChange" v-model="alias"></fm-input>
        </div>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">返回结果</div>
      <div class="form-item-content">
        <result @change="valueChange" v-model="result"></result>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">关联表</div>
      <div class="form-item-content">
        <joins @change="valueChange" v-model="joins"></joins>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">查询条件</div>
      <div class="form-item-content">
        <conditions @change="valueChange" v-model="conditions"></conditions>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">排序</div>
      <div class="form-item-content">
        <orders @change="valueChange" v-model="orders"></orders>
      </div>
    </div>
  </div>
</template>

<script>
import Result from './result'
import Joins from './joins'
import Conditions from './conditions'
import Orders from './orders'
let dcopy = require('deep-copy')

export default {
  name: 'conf-table',
  components: {
    Result,
    Joins,
    Conditions,
    Orders
  },
  props: {
    value: Object
  },
  data () {
    return {
      table: '',
      alias: '',
      result: {
        isAll: true,
        fields: [],
        excludeColumns: []
      },
      joins: [],
      conditions: [],
      orders: []
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value: {
      handler () {
        this.table = this.value.table || ''
        this.alias = this.value.alias || ''
        this.joins = dcopy(this.value.joins) || []
        this.conditions = dcopy(this.value.conditions) || []
        this.orders = dcopy(this.value.orders) || []
        this.result = dcopy(this.value.result) || {
          isAll: true,
          fields: [],
          excludeColumns: []
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleClose () {
      this.$emit('handleClose')
    },
    valueChange () {
      this.$emit('change', {
        table: this.table,
        alias: this.alias,
        result: dcopy(this.result),
        joins: dcopy(this.joins),
        orders: dcopy(this.orders),
        conditions: dcopy(this.conditions)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title-c {
  display: flex;
  align-items: center;
}
.form-item-table {
  height: 10rem;
  width: 100%;
}
.form-item {
  display: flex;
  margin: 0.5rem 1rem;
  display: flex;
  align-items: center;
  .form-item-label {
    min-width: 5rem;
    max-width: 5rem;
    width: 5rem;
  }
  .form-item-content {
    flex: 1;
  }
}
</style>
