<template>
  <div>
    <div class="form-item">
      <div class="form-item-label">所有字段</div>
      <div class="form-item-content">
        <div style="width: 10rem;margin-left: 1rem;">
          <fm-checkbox @change="valueChange" v-model="isAll"></fm-checkbox>
        </div>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">返回字段</div>
      <div class="form-item-content">
        <column @change="valueChange" v-model="fields"></column>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">排除字段</div>
      <div class="form-item-content">
        <div style="width: 10rem;margin-left: 1rem;">
          <fm-input @change="valueChange" v-model="excludeColumns"></fm-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Column from './column'

let dcopy = require('deep-copy')

export default {
  name: 'result',
  components: {
    Column
  },
  props: {
    value: Object
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value: {
      handler () {
        this.isAll = typeof this.value.isAll === 'boolean' ? this.value.isAll : true
        this.fields = this.value.fields ? dcopy(this.value.fields) : []
        this.excludeColumns = dcopy(this.value.excludeColumns || []).join(',')
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      isAll: false,
      fields: [],
      excludeColumns: ''
    }
  },
  methods: {
    valueChange () {
      this.$emit('change', {
        isAll: this.isAll,
        excludeColumns: this.excludeColumns ? this.excludeColumns.split(',') : [],
        fields: dcopy(this.fields)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './style.less';
</style>
